import { addAlerts, updateAlerts } from "store/slices/alertSlice";
import store from "store/";
import { listCompany } from "./dashboard/CompanyManagement";
import { listUser } from "./dashboard/userManagement";
import { listAlertManagement } from "./dashboard/AlertManagement";
import { listCameraByUser } from "./dashboard/events";

export function addToQueue(
  api,
  isError,
  message,
  errorCode,
  loader = false,
  id = null
) {
  if (id === null) {
    id = new Date().getTime();
    store.dispatch(
      addAlerts({
        id,
        type: isError ? "error" : "success",
        message,
        loader,
        errorCode,
        isError,
        api,
        isComplete: false,
      })
    );
  } else {
    store.dispatch(
      updateAlerts({
        id,
        type: isError ? "error" : "success",
        message,
        loader,
        errorCode,
        isError,
        api,
        isComplete: true,
      })
    );
  }
  return id;
}

export const initailization = () => async (dispatch) => {
  dispatch(listCompany());
  if (store.getState().auth.userDetails.role === "SUPER-USER") {
    dispatch(listUser());
  }
  dispatch(
    listCameraByUser({
      company: store.getState().auth.userDetails.company_id[0].company,
      branch: store.getState().auth.userDetails.company_id[0].branch,
    })
  );
  dispatch(
    listAlertManagement({
      company: store.getState().auth.userDetails.company_id[0].company,
      branch: store.getState().auth.userDetails.company_id[0].branch,
    })
  );
};
