import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  alertTrigger: false,
  alertQueue: [],
};

export const alertSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addAlerts: (state, action) => {
      state.alertTrigger = true;
      state.alertQueue.push(action.payload);
    },
    removeAlerts: (state, action) => {
      state.alertTrigger = true;
      state.alertQueue = state.alertQueue.map((ele) => {
        if (ele.id === action.payload.id) {
          return { ...ele, isComplete: true };
        } else {
          return ele;
        }
      });
    },
    clearAlerts: (state, action) => {
      state.alertTrigger = true;
      state.alertQueue = [];
    },
    updateAlerts: (state, action) => {
      state.alertTrigger = true;
      state.alertQueue = state.alertQueue.map((ele) => {
        if (ele.id === action.payload.id) {
          return action.payload;
        } else {
          return ele;
        }
      });
    },
  },
});

export const { addAlerts, removeAlerts, clearAlerts, updateAlerts } =
  alertSlice.actions;

export default alertSlice.reducer;
