import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  selectedId: null,
  selectedIdHash: null,
  selectedTopId: null,
  selectedTopIdHash: null,
  edit: {},
  creating: null,
  editing: null,
  data: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreating: (state, action) => {
      state.creating = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
      sessionStorage.setItem("selectedIdProject", action.payload);
      if (action.payload === null) {
        sessionStorage.removeItem("selectedIdProject");
      }
    },
    setSelectedTopId: (state, action) => {
      state.selectedTopId = action.payload;
      sessionStorage.setItem("selectedTopIdProject", action.payload);
      if (action.payload === null) {
        sessionStorage.removeItem("selectedTopIdProject");
      }
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },

    setSelectedIdHash: (state, action) => {
      state.selectedIdHash = action.payload;
    },
    setSelectedTopIdHash: (state, action) => {
      state.selectedTopIdHash = action.payload;
    },
  },
});

export const {
  setData,
  setLoading,
  setSelectedId,
  setEdit,
  setEditing,
  setCreating,
  setSelectedTopId,
  setSelectedIdHash,
  setSelectedTopIdHash,
} = projectSlice.actions;

export default projectSlice.reducer;
