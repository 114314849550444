import { addToQueue } from "api/generic";
import axios from "axios";
import { API_ACCESS_CONTROL, CONFIG } from "constants/ApiConstant";
import {
  setCreating,
  setData,
  setLoading,
  setSelectedId,
  setSelectedTopId,
} from "store/slices/companySlice";
import {
  setSelectedId as setProjectId,
  setSelectedTopId as setProjectTopId,
} from "store/slices/projectSlice";
import {
  deactivateUserCompanyRole,
  getRole,
  userCompanyRole,
} from "./userManagement";
import { logoutFunc } from "api/auth/auth";

let listCompanyType = null;
export const listCompany = () => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "org/list-company";
  if (listCompanyType !== null) {
    listCompanyType.cancel("New Call made");
    listCompanyType = null;
  }
  listCompanyType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: {},
    headers: CONFIG,
    cancelToken: listCompanyType.token,
  })
    .then((response) => {
      listCompanyType = null;
      if (response.data.statuscode === 200) {
        dispatch(setProjectTopId(null));
        dispatch(setProjectId(null));
        dispatch(setLoading(false));
        dispatch(setData(response.data.data));
        if (sessionStorage.getItem("selectedId")) {
          if (
            response.data.data.filter(
              (ele) =>
                ele.status &&
                ele.id === parseInt(sessionStorage.getItem("selectedId"))
            ).length > 0
          ) {
            dispatch(
              setSelectedId(parseInt(sessionStorage.getItem("selectedId")))
            );
            dispatch(
              setSelectedTopId(
                parseInt(sessionStorage.getItem("selectedTopId"))
              )
            );
          } else {
            if (response.data.data.filter((ele) => ele.status).length > 0) {
              dispatch(
                setSelectedId(
                  response.data.data.filter((ele) => ele.status)[0].id
                )
              );
              dispatch(
                setSelectedTopId(
                  response.data.data.filter((ele) => ele.status)[0].id
                )
              );
              sessionStorage.setItem(
                "selectedId",
                response.data.data.filter((ele) => ele.status)[0].id
              );
              sessionStorage.setItem(
                "selectedTopId",
                response.data.data.filter((ele) => ele.status)[0].id
              );
            }
          }
        } else {
          if (response.data.data.filter((ele) => ele.status).length > 0) {
            dispatch(
              setSelectedId(
                response.data.data.filter((ele) => ele.status)[0].id
              )
            );
            dispatch(
              setSelectedTopId(
                response.data.data.filter((ele) => ele.status)[0].id
              )
            );
            sessionStorage.setItem(
              "selectedId",
              response.data.data.filter((ele) => ele.status)[0].id
            );
            sessionStorage.setItem(
              "selectedTopId",
              response.data.data.filter((ele) => ele.status)[0].id
            );
          }
        }
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let addCompanyType = null;
export const addCompany = (values, userId) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "org/create-company";
  if (addCompanyType !== null) {
    addCompanyType.cancel("New Call made");
    addCompanyType = null;
  }
  addCompanyType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: addCompanyType.token,
  })
    .then(async (response) => {
      addCompanyType = null;
      if (response.data.statuscode === 200) {
        let id = await getRole({ role_name: "COMPANY-ADMIN" });
        if (id !== null) {
          let res = await userCompanyRole({
            user: userId,
            company: response.data.data,
            role: id,
            flag: "COMPANY-MANAGEMENT",
          });
          if (res !== null) {
            if (res.statuscode === 200) {
              dispatch(setCreating("success"));
              dispatch(listCompany());
              setTimeout(() => {
                dispatch(setCreating(null));
              }, 100);
              addToQueue(url, false, response.data.message, 200);
            } else {
              dispatch(setCreating("error"));
              setTimeout(() => {
                dispatch(setCreating(null));
              }, 100);
              addToQueue(
                url,
                true,
                res.message
                  ? res.message
                  : "Something went wrong! Please try again",
                res.statuscode ? res.statuscode : 500
              );
            }
          } else {
            addToQueue(
              url,
              true,
              "Something went wrong! Please try again",
              500
            );
            dispatch(setCreating("error"));
            setTimeout(() => {
              dispatch(setCreating(null));
            }, 100);
          }
        }
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let editCompanyType = null;
export const editCompany =
  (values, userId = null, companyAdmin = null) =>
  async (dispatch) => {
    dispatch(setCreating("creating"));
    const url = "org/edit-company";
    if (editCompanyType !== null) {
      editCompanyType.cancel("New Call made");
      editCompanyType = null;
    }
    editCompanyType = axios.CancelToken.source();
    await axios({
      method: "POST",
      url: API_ACCESS_CONTROL + url,
      data: values,
      headers: CONFIG,
      cancelToken: editCompanyType.token,
    })
      .then(async (response) => {
        editCompanyType = null;
        if (response.data.statuscode === 200) {
          if (userId === null) {
            dispatch(setCreating("success"));
            dispatch(listCompany());
            setTimeout(() => {
              dispatch(setCreating(null));
            }, 100);
            addToQueue(url, false, response.data.message, 200);
          } else {
            let id = await getRole({ role_name: "COMPANY-ADMIN" });
            if (id !== null) {
              let deactivate = await deactivateUserCompanyRole({
                user: companyAdmin,
                role: id,
                company: values.company_id,
              });
              if (deactivate !== null) {
                if (deactivate.statuscode === 200) {
                  let res = await userCompanyRole({
                    user: userId,
                    company: response.data.data,
                    role: id,
                    flag: "COMPANY-MANAGEMENT",
                  });
                  if (res !== null) {
                    if (res.statuscode === 200) {
                      dispatch(setCreating("success"));
                      dispatch(listCompany());
                      setTimeout(() => {
                        dispatch(setCreating(null));
                      }, 100);
                      addToQueue(url, false, response.data.message, 200);
                    } else {
                      dispatch(setCreating("error"));
                      setTimeout(() => {
                        dispatch(setCreating(null));
                      }, 100);
                      addToQueue(
                        url,
                        true,
                        res.message
                          ? res.message
                          : "Something went wrong! Please try again",
                        res.statuscode ? res.statuscode : 500
                      );
                    }
                  } else {
                    addToQueue(
                      url,
                      true,
                      "Something went wrong! Please try again",
                      500
                    );
                    dispatch(setCreating("error"));
                    setTimeout(() => {
                      dispatch(setCreating(null));
                    }, 100);
                  }
                } else {
                  addToQueue(
                    url,
                    true,
                    deactivate.message
                      ? deactivate.message
                      : "Something went wrong! Please try again",
                    deactivate.statuscode ? deactivate.statuscode : 500
                  );
                  dispatch(setCreating("error"));
                  setTimeout(() => {
                    dispatch(setCreating(null));
                  }, 100);
                }
              } else {
                addToQueue(
                  url,
                  true,
                  "Something went wrong! Please try again",
                  500
                );
                dispatch(setCreating("error"));
                setTimeout(() => {
                  dispatch(setCreating(null));
                }, 100);
                dispatch(setCreating(null));
              }
            }
          }
        } else if (response.data.statuscode === 403) {
          dispatch(setCreating("error"));
          setTimeout(() => {
            dispatch(setCreating(null));
          }, 100);
          dispatch(logoutFunc());
        } else {
          dispatch(setCreating("error"));
          setTimeout(() => {
            dispatch(setCreating(null));
          }, 100);
          addToQueue(
            url,
            true,
            response.data.message
              ? response.data.message
              : "Something went wrong! Please try again",
            response.data.statuscode ? response.data.statuscode : 500
          );
        }
      })
      .catch((error) => {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
      });
  };

let deactivateCompanyType = null;
export const deactivateCompany = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "org/delete-company";
  if (deactivateCompanyType !== null) {
    deactivateCompanyType.cancel("New Call made");
    deactivateCompanyType = null;
  }
  deactivateCompanyType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: deactivateCompanyType.token,
  })
    .then((response) => {
      deactivateCompanyType = null;
      if (response.data.statuscode === 200) {
        dispatch(listCompany());
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

export const removeCompanyAdmin = (values) => async (dispatch) => {
  let id = await getRole({ role_name: "COMPANY-ADMIN" });
  if (id !== null) {
    let deactivate = await deactivateUserCompanyRole({
      user: values.id,
      role: id,
      company: values.company_id,
    });
    if (deactivate !== null) {
      if (deactivate.statuscode === 200) {
        addToQueue(
          "Remove company admin",
          false,
          deactivate.message
            ? deactivate.message
            : "Something went wrong! Please try again",
          deactivate.statuscode ? deactivate.statuscode : 500
        );
        dispatch(setCreating("success"));
        dispatch(listCompany());
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
      } else {
        addToQueue(
          "Remove company admin",
          true,
          deactivate.message
            ? deactivate.message
            : "Something went wrong! Please try again",
          deactivate.statuscode ? deactivate.statuscode : 500
        );
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
      }
    } else {
      addToQueue(
        "Remove company admin",
        true,
        "Something went wrong! Please try again",
        500
      );
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
      dispatch(setCreating(null));
    }
  }
};
