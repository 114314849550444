import { createSlice } from "@reduxjs/toolkit";
import { AUTH_TOKEN, USER_DETAILS } from "constants/AuthConstant";

export const initialState = {
  isAuthenticated: sessionStorage.getItem(AUTH_TOKEN) ? true : false,
  loading: true,
  isOtp: false,
  otp_time: 0,
  userDetails: sessionStorage.getItem(USER_DETAILS)
    ? JSON.parse(sessionStorage.getItem(USER_DETAILS))
    : null,
  token: sessionStorage.getItem(AUTH_TOKEN)
    ? sessionStorage.getItem(AUTH_TOKEN)
    : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.loading = action.payload.loading;
      state.isOtp = action.payload.isOtp;
      state.userDetails = action.payload.userDetails;
      sessionStorage.setItem(
        USER_DETAILS,
        JSON.stringify(action.payload.userDetails)
      );
    },
    setName: (state, action) => {
      state.userDetails.name = action.payload.name;
      state.userDetails.first_name = action.payload.first_name;
      state.userDetails.last_name = action.payload.last_name;
      sessionStorage.setItem(USER_DETAILS, JSON.stringify(state.userDetails));
    },
    loadingState: (state, action) => {
      state.loading = action.payload;
    },
    isOtpState: (state, action) => {
      state.isOtp = action.payload;
    },
    setOtpTime: (state, action) => {
      state.otp_time = action.payload;
    },
    signOut: (state, action) => {
      state.token = null;
      state.loading = false;
      state.isOtp = false;
      state.userDetails = null;
      sessionStorage.clear();
      window.location.href = "/auth";
    },
    authenticated: (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
      sessionStorage.setItem(AUTH_TOKEN, action.payload.token);
      state.userDetails = {
        ...state.userDetails,
        company_id: action.payload.userDetails,
      };
      sessionStorage.setItem(
        USER_DETAILS,
        JSON.stringify({
          ...state.userDetails,
          company_id: action.payload.userDetails,
        })
      );
    },
  },
});

export const {
  signIn,
  loadingState,
  signOut,
  authenticated,
  isOtpState,
  setName,
  setOtpTime,
} = authSlice.actions;

export default authSlice.reducer;
