import { addToQueue, initailization } from "api/generic";
import axios from "axios";
import { API_ACCESS_CONTROL, CONFIG, PUBLIC_KEY } from "constants/ApiConstant";
import {
  signIn,
  signOut,
  loadingState,
  authenticated,
  setOtpTime,
} from "store/slices/authSlice";
import { Crypt } from "hybrid-crypto-js";
import setAuthToken from "utils/token";
import store from "store";
import { AUTH_TOKEN } from "constants/AuthConstant";

let loginRef = null;
export const login = (email, password) => async (dispatch) => {
  dispatch(loadingState(true));
  const url = "access/loginapi";
  if (loginRef !== null) {
    loginRef.cancel("New Call made");
    loginRef = null;
  }
  loginRef = axios.CancelToken.source();
  var crypt = new Crypt();
  var encrypted = crypt.encrypt(PUBLIC_KEY, password);
  var data = JSON.parse(encrypted);
  var encData =
    data.iv + "$|" + data.cipher + "$|" + data.keys[Object.keys(data.keys)[0]];

  const body = { email, password: encData };
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: body,
    headers: CONFIG,
    cancelToken: loginRef.token,
  })
    .then((response) => {
      loginRef = null;
      if (response.data.statuscode === 200) {
        dispatch(
          signIn({
            loading: false,
            isOtp: true,
            userDetails: {
              name: response.data.name,
              email: response.data.email,
              role: response.data.role,
              company_id: response.data.company_id,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              id: response.data.user_id,
            },
          })
        );
      } else if (response.data.statuscode === 403) {
        dispatch(signOut());
      } else {
        dispatch(loadingState(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode
        );
      }
    })
    .catch((error) => {
      loginRef = null;
      dispatch(loadingState(false));
    });
};

let otpRef = null;
export const otpValidate = (email, otp) => async (dispatch) => {
  dispatch(loadingState(true));
  const url = "access/validate-otp";
  if (otpRef !== null) {
    otpRef.cancel("New Call made");
    otpRef = null;
  }
  otpRef = axios.CancelToken.source();

  const body = { email, otp };
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: body,
    headers: CONFIG,
    cancelToken: otpRef.token,
  })
    .then((response) => {
      otpRef = null;
      if (response.data.statuscode === 200) {
        dispatch(authenticated({ token: response.data.token }));
        setAuthToken(response.data.token);
        const { userDetails } = store.getState().auth;
        if (userDetails !== null) {
          if (
            typeof userDetails.role === "string" &&
            userDetails.role !== null
          ) {
            if (userDetails.role.toLowerCase() === "super-user") {
              window.location.href = "/app/company-management";
            } else if (userDetails.role.toLowerCase() === "company-admin") {
              window.location.href = "/app/project-management";
            } else if (userDetails.role.toLowerCase() === "project-admin") {
              window.location.href = "/app/camera-management";
            } else if (userDetails.role.toLowerCase() === "user") {
              window.location.href = "/app/security-center";
            } else if (userDetails.role.toLowerCase() === "custom-user") {
              window.location.href = "/app/camera-controls";
            }
          }
        }
      } else if (response.data.statuscode === 403) {
        dispatch(signOut());
      } else {
        dispatch(loadingState(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode
        );
      }
    })
    .catch((error) => {
      otpRef = null;
      dispatch(loadingState(false));
    });
};

let resetMail = null;
export const forgotPassword = (email, history) => async (dispatch) => {
  // const history = useHistory();
  dispatch(loadingState(true));
  const url = "access/reset-password";
  if (resetMail !== null) {
    resetMail.cancel("New Call made");
    resetMail = null;
  }
  resetMail = axios.CancelToken.source();

  const body = { email };
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: body,
    headers: CONFIG,
    cancelToken: resetMail.token,
  })
    .then((response) => {
      resetMail = null;
      if (response.data.statuscode === 200) {
        history("/auth/reset-password?user_email=" + email);
      } else if (response.data.statuscode === 403) {
        dispatch(signOut());
      } else {
        dispatch(loadingState(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode
        );
      }
    })
    .catch((error) => {
      resetMail = null;
      dispatch(loadingState(false));
    });
};

let forgotMail = null;
export const resetPassword =
  (email, otp, new_password, history) => async (dispatch) => {
    dispatch(loadingState(true));
    const url = "access/update-password";
    if (forgotMail !== null) {
      forgotMail.cancel("New Call made");
      forgotMail = null;
    }
    forgotMail = axios.CancelToken.source();

    var crypt = new Crypt();
    var encrypted = crypt.encrypt(PUBLIC_KEY, new_password);
    var data = JSON.parse(encrypted);
    var encData =
      data.iv +
      "$|" +
      data.cipher +
      "$|" +
      data.keys[Object.keys(data.keys)[0]];
    const body = { email, otp, new_password: encData };
    await axios({
      method: "POST",
      url: API_ACCESS_CONTROL + url,
      data: body,
      headers: CONFIG,
      cancelToken: forgotMail.token,
    })
      .then((response) => {
        forgotMail = null;
        if (response.data.statuscode === 200) {
          dispatch(history("/auth/login"));
        } else if (response.data.statuscode === 403) {
          dispatch(signOut());
        } else {
          dispatch(loadingState(false));
          addToQueue(
            url,
            true,
            response.data.message
              ? response.data.message
              : "Something went wrong! Please try again",
            response.data.statuscode
          );
        }
      })
      .catch((error) => {
        forgotMail = null;
        dispatch(loadingState(false));
      });
  };

export const getOtpTime = (email) => async (dispatch) => {
  const url = "access/get-otp-expiry-duration";
  const body = { email };
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: body,
    headers: CONFIG,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch(setOtpTime(response.data.duration));
      } else {
        dispatch(setOtpTime(0));
      }
    })
    .catch((error) => {
      dispatch(setOtpTime(0));
    });
};

//OTP Timer for reset password
export const resendOtp = (email) => async (dispatch) => {
  const url = "access/resend-otp";
  const body = { email };
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: body,
    headers: CONFIG,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        dispatch(setOtpTime(response.data.duration));
      } else {
        dispatch(setOtpTime(0));
      }
    })
    .catch((error) => {
      dispatch(setOtpTime(0));
    });
};

let logoutType = null;
export const logoutFunc = () => async (dispatch) => {
  const url = "access/logout";
  if (logoutType !== null) {
    logoutType.cancel("New Call made");
    logoutType = null;
  }
  logoutType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: { email: store.getState().auth.userDetails.email },
    headers: CONFIG,
    cancelToken: logoutType.token,
  })
    .then((response) => {
      if (response.data.statuscode === 200) {
        setAuthToken(false);
        dispatch(signOut());
        window.location.href = "/auth";
      } else {
        setAuthToken(false);
        dispatch(signOut());
        window.location.href = "/auth";
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {});
};

let checkTokenType = null;
export const checkToken = () => async (dispatch) => {
  if (!sessionStorage.getItem(AUTH_TOKEN)) {
    dispatch(loadingState(false));
    return;
  }
  const url = "access/check-token";
  if (checkTokenType !== null) {
    checkTokenType.cancel("New Call made");
    checkTokenType = null;
  }
  checkTokenType = axios.CancelToken.source();
  let header = JSON.parse(JSON.stringify(CONFIG));
  header["Authorization"] = sessionStorage.getItem(AUTH_TOKEN);
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: {},
    headers: header,
    cancelToken: checkTokenType.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        await dispatch(
          authenticated({
            token: response.data.new_token,
            userDetails: response.data.company_id,
          })
        );
        await setAuthToken(null);
        await setAuthToken(response.data.new_token);
        setTimeout(() => {
          dispatch(initailization());
        }, 100);
      } else if (response.data.statuscode === 403) {
        dispatch(loadingState(false));
        dispatch(signOut());
      } else {
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {});
};
