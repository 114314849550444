import { addToQueue } from "api/generic";
import axios from "axios";
import { API_OPERATIONS, CONFIG } from "constants/ApiConstant";
import {
  setCreating,
  setData,
  setLoading,
} from "store/slices/alertManagementSlice";
import store from "store";
import { logoutFunc } from "api/auth/auth";

let listAlertManagementType = null;
export const listAlertManagement = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "project-management/list-sc-user";
  if (listAlertManagementType !== null) {
    listAlertManagementType.cancel("New Call made");
    listAlertManagementType = null;
  }
  listAlertManagementType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: listAlertManagementType.token,
  })
    .then((response) => {
      listAlertManagementType = null;
      if (response.data.statuscode === 200) {
        dispatch(setLoading(false));
        dispatch(setData(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let addAlertManagementType = null;
export const addAlertManagement = (values, cameras) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "project-management/register-sc-user";
  if (addAlertManagementType !== null) {
    addAlertManagementType.cancel("New Call made");
    addAlertManagementType = null;
  }
  addAlertManagementType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: addAlertManagementType.token,
  })
    .then(async (response) => {
      addAlertManagementType = null;
      if (response.data.statuscode === 200) {
        cameras.id = response.data.data;
        dispatch(addAlertManagementCamera(cameras));
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let addAlertManagementCameraType = null;
export const addAlertManagementCamera = (values) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "project-management/assign-user-camera-access";
  if (addAlertManagementCameraType !== null) {
    addAlertManagementCameraType.cancel("New Call made");
    addAlertManagementCameraType = null;
  }
  addAlertManagementCameraType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: addAlertManagementCameraType.token,
  })
    .then(async (response) => {
      addAlertManagementCameraType = null;
      if (response.data.statuscode === 200) {
        dispatch(setCreating("success"));
        dispatch(
          listAlertManagement({
            company: store.getState().auth.userDetails.company_id[0].company,
          })
        );
        setTimeout(() => {
          dispatch(setCreating(null));
        });
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let editAlertManagementCameraType = null;
export const editAlertManagementCamera = (values) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "project-management/edit-user-camera-access";
  if (editAlertManagementCameraType !== null) {
    editAlertManagementCameraType.cancel("New Call made");
    editAlertManagementCameraType = null;
  }
  editAlertManagementCameraType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: editAlertManagementCameraType.token,
  })
    .then(async (response) => {
      editAlertManagementCameraType = null;
      if (response.data.statuscode === 200) {
        dispatch(setCreating("success"));
        dispatch(
          listAlertManagement({
            company: store.getState().auth.userDetails.company_id[0].company,
          })
        );
        setTimeout(() => {
          dispatch(setCreating(null));
        });
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let editAlertManagementType = null;
export const editAlertManagement = (values) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "project-management/edit-sc-user";
  if (editAlertManagementType !== null) {
    editAlertManagementType.cancel("New Call made");
    editAlertManagementType = null;
  }
  editAlertManagementType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: editAlertManagementType.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(setCreating("success"));
        dispatch(
          listAlertManagement({
            company: values.company,
            branch: values.branch,
          })
        );
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let deactiveUsers = null;
export const deactivateSCUsers = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "project-management/delete-sc-user";
  if (deactiveUsers !== null) {
    deactiveUsers.cancel("New Call made");
    deactiveUsers = null;
  }
  deactiveUsers = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: deactiveUsers.token,
  })
    .then((response) => {
      deactiveUsers = null;
      if (response.data.statuscode === 200) {
        dispatch(
          listAlertManagement({
            company: values.company,
          })
        );
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let removeCameraAlert = null;
export const removeCameraAlerts = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "project-management/remove-user-camera-access";
  if (removeCameraAlert !== null) {
    removeCameraAlert.cancel("New Call made");
    removeCameraAlert = null;
  }
  removeCameraAlert = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: removeCameraAlert.token,
  })
    .then((response) => {
      removeCameraAlert = null;
      if (response.data.statuscode === 200) {
        dispatch(setCreating("success"));
        setTimeout(() => {
          dispatch(setCreating(null));
        });
        addToQueue(url, false, response.data.message, 200);
        dispatch(
          listAlertManagement({
            company: values.company,
            branch: values.branch,
          })
        );
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};
