import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const { userDetails } = useSelector((state) => state.auth);
  const [entry, setentry] = useState(null);
  useEffect(() => {
    if (userDetails !== null) {
      if (typeof userDetails.role === "string") {
        let role = userDetails.role.toLowerCase();
        if (role === "super-user") {
          setentry("/app/company-management");
        } else if (role === "company-admin") {
          setentry("/app/project-management");
        } else if (role === "project-admin") {
          setentry("/app/camera-management");
        } else if (role === "user") {
          setentry("/app/security-center");
        } else {
          setentry("/app/camera-controls");
        }
      }
    }
  }, [userDetails]);

  const { isAuthenticated } = useSelector((state) => state.auth);
  return isAuthenticated && entry !== null ? (
    <Navigate to={entry} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
