import { logoutFunc } from "api/auth/auth";
import { addToQueue } from "api/generic";
import axios from "axios";
import { API_ACCESS_CONTROL, CONFIG } from "constants/ApiConstant";
import {
  setCreating,
  setData,
  setLoading,
  setLoadingModel,
  setModelData,
  setUserByEmail,
} from "store/slices/userSlice";

let checkUserByEmailType = null;
export const checkUserByEmail = (values) => async (dispatch) => {
  const url = "access/list-user-byemail";
  if (checkUserByEmailType !== null) {
    checkUserByEmailType.cancel("New Call made");
    checkUserByEmailType = null;
  }
  checkUserByEmailType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: checkUserByEmailType.token,
  })
    .then((response) => {
      checkUserByEmailType = null;
      if (response.data.statuscode === 200) {
        dispatch(setUserByEmail(response.data));
      } else if (response.data.statuscode === 403) {
        dispatch(logoutFunc());
      } else {
        dispatch(setUserByEmail(response.data));
      }
    })
    .catch((error) => {});
};

let createNewUserType = null;
export const createNewUser =
  (values, stage = null) =>
  async (dispatch) => {
    dispatch(setCreating("creating"));
    const url = "access/register-user";
    if (createNewUserType !== null) {
      createNewUserType.cancel("New Call made");
      createNewUserType = null;
    }
    createNewUserType = axios.CancelToken.source();
    await axios({
      method: "POST",
      url: API_ACCESS_CONTROL + url,
      data: values,
      headers: CONFIG,
      cancelToken: createNewUserType.token,
    })
      .then((response) => {
        createNewUserType = null;
        if (response.data.statuscode === 200) {
          addToQueue(url, false, response.data.message, 200);
          if (stage === null) {
            dispatch(checkUserByEmail({ email: values.email }));
          } else {
            dispatch(setCreating("success"));
            dispatch(listUser());
            setTimeout(() => {
              dispatch(setCreating(null));
            }, 100);
          }
        } else if (response.data.statuscode === 403) {
          dispatch(logoutFunc());
        } else {
          addToQueue(
            url,
            true,
            response.data.message
              ? response.data.message
              : "Something went wrong! Please try again",
            response.data.statuscode ? response.data.statuscode : 500
          );
        }
      })
      .catch((error) => {});
  };

let getRoleType = null;
export const getRole = async (values) => {
  const url = "access/get-role";
  if (getRoleType !== null) {
    getRoleType.cancel("New Call made");
    getRoleType = null;
  }
  getRoleType = axios.CancelToken.source();
  try {
    const response = await axios.post(API_ACCESS_CONTROL + url, values, {
      headers: CONFIG,
      cancelToken: getRoleType.token,
    });
    if (response.data.statuscode === 200) {
      getRoleType = null;
      return response.data.data.id;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

let userByEmailReturnType = null;
export const userByEmailReturn = async (values, data = false) => {
  const url = "access/list-user-byemail";
  if (userByEmailReturnType !== null) {
    userByEmailReturnType.cancel("New Call made");
    userByEmailReturnType = null;
  }
  userByEmailReturnType = axios.CancelToken.source();
  try {
    const response = await axios.post(API_ACCESS_CONTROL + url, values, {
      headers: CONFIG,
      cancelToken: userByEmailReturnType.token,
    });
    if (response.data.statuscode === 200 || response.data.statuscode === 400) {
      userByEmailReturnType = null;
      return data ? response.data : response.data.isNew;
    } else {
      userByEmailReturnType = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let userCompanyRoleType = null;
export const userCompanyRole = async (values) => {
  const url = "org/create-usercompanyrole";
  if (userCompanyRoleType !== null) {
    userCompanyRoleType.cancel("New Call made");
    userCompanyRoleType = null;
  }
  userCompanyRoleType = axios.CancelToken.source();
  try {
    const response = await axios.post(API_ACCESS_CONTROL + url, values, {
      headers: CONFIG,
      cancelToken: userCompanyRoleType.token,
    });
    if (response.data.statuscode === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

let deactiveuserCompanyRoleType = null;
export const deactivateUserCompanyRole = async (values) => {
  const url = "org/deactivate-usercompanyrole";
  if (deactiveuserCompanyRoleType !== null) {
    deactiveuserCompanyRoleType.cancel("New Call made");
    deactiveuserCompanyRoleType = null;
  }
  deactiveuserCompanyRoleType = axios.CancelToken.source();
  try {
    const response = await axios.post(API_ACCESS_CONTROL + url, values, {
      headers: CONFIG,
      cancelToken: deactiveuserCompanyRoleType.token,
    });
    if (response.data.statuscode === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

export const userProjectRole = async (values) => {
  const url = "project/assign-project";
  if (userCompanyRoleType !== null) {
    userCompanyRoleType.cancel("New Call made");
    userCompanyRoleType = null;
  }
  userCompanyRoleType = axios.CancelToken.source();
  try {
    const response = await axios.post(API_ACCESS_CONTROL + url, values, {
      headers: CONFIG,
      cancelToken: userCompanyRoleType.token,
    });
    if (response.data.statuscode === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

let listUserType = null;
export const listUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "org/list-users";
  if (listUserType !== null) {
    listUserType.cancel("New Call made");
    listUserType = null;
  }
  listUserType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: {},
    headers: CONFIG,
    cancelToken: listUserType.token,
  })
    .then((response) => {
      listUserType = null;
      if (response.data.statuscode === 200) {
        dispatch(setLoading(false));
        dispatch(setData(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let editUserType = null;
export const editUser = (values) => async (dispatch) => {
  dispatch(setCreating("creating"));
  const url = "access/edit-user-byid";
  if (editUserType !== null) {
    editUserType.cancel("New Call made");
    editUserType = null;
  }
  editUserType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: editUserType.token,
  })
    .then(async (response) => {
      if (response.data.statuscode === 200) {
        dispatch(setCreating("success"));
        dispatch(listUser());
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        dispatch(logoutFunc());
      } else {
        dispatch(setCreating("error"));
        setTimeout(() => {
          dispatch(setCreating(null));
        }, 100);
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCreating("error"));
      setTimeout(() => {
        dispatch(setCreating(null));
      }, 100);
    });
};

let deactivateUserType = null;
export const deactivateUser = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "access/deactivate-user";
  if (deactivateUserType !== null) {
    deactivateUserType.cancel("New Call made");
    deactivateUserType = null;
  }
  deactivateUserType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: deactivateUserType.token,
  })
    .then((response) => {
      deactivateUserType = null;
      if (response.data.statuscode === 200) {
        dispatch(setLoading(true));
        dispatch(listUser());
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let listCompanyType = null;
export const listCompanyByEmail = (values) => async (dispatch) => {
  dispatch(setLoadingModel(true));
  const url = "org/list-company-byemail";
  if (listCompanyType !== null) {
    listCompanyType.cancel("New Call made");
    listCompanyType = null;
  }
  listCompanyType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: listCompanyType.token,
  })
    .then((response) => {
      listCompanyType = null;
      if (response.data.statuscode === 200) {
        dispatch(setLoadingModel(false));
        dispatch(setModelData(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(setModelData([]));
        dispatch(setLoadingModel(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setModelData([]));
        dispatch(setLoadingModel(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setModelData([]));
      dispatch(setLoadingModel(false));
    });
};

let listProjectType = null;
export const listProjectByEmail = (values) => async (dispatch) => {
  dispatch(setLoadingModel(true));
  const url = "project/list-project-byemail";
  if (listProjectType !== null) {
    listProjectType.cancel("New Call made");
    listProjectType = null;
  }
  listProjectType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_ACCESS_CONTROL + url,
    data: values,
    headers: CONFIG,
    cancelToken: listProjectType.token,
  })
    .then((response) => {
      listProjectType = null;
      if (response.data.statuscode === 200) {
        dispatch(setLoadingModel(false));
        dispatch(setModelData(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(setModelData([]));
        dispatch(setLoadingModel(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setModelData([]));
        dispatch(setLoadingModel(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setModelData([]));
      dispatch(setLoadingModel(false));
    });
};
