export const API_ACCESS_CONTROL =
  process.env.REACT_APP_API_URL + process.env.REACT_APP_API_TYPE_ACCESS;
export const API_OPERATIONS =
  process.env.REACT_APP_API_URL + process.env.REACT_APP_API_TYPE_OPERATIONS;
export const CONFIG = {
  "Content-Type": "application/json",
};

export const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC13UDmL+Acqc2LJGyrBGJTnb2o
9KvnrFftGSQPvE1tDoqV6Ms2602sQVL/TX4inZuDr5axIBNIdWsuU6xe0eX3TMw5
2WOnTcNtLFDiCB1IaWGgOHWQ1kaL5coz4v+PR4ymPfBLZSqS+c0BzM6RWyXnsFv8
Br7O1xXKjDpKozcQCQIDAQAB
-----END PUBLIC KEY-----`;
