import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "otp",
    path: `${AUTH_PREFIX_PATH}/otp`,
    component: React.lazy(() => import("views/auth-views/authentication/otp")),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "camera-controls",
    path: `${APP_PREFIX_PATH}/camera-controls`,
    component: React.lazy(() => import("views/app-views/CameraControls/")),
  },
  {
    key: "company-management",
    path: `${APP_PREFIX_PATH}/company-management`,
    component: React.lazy(() => import("views/app-views/CompanyManagement/")),
  },
  {
    key: "project-management",
    path: `${APP_PREFIX_PATH}/project-management`,
    component: React.lazy(() => import("views/app-views/ProjectManagement/")),
  },
  {
    key: "camera-management",
    path: `${APP_PREFIX_PATH}/camera-management`,
    component: React.lazy(() => import("views/app-views/CameraManagement/")),
  },
  {
    key: "access-management",
    path: `${APP_PREFIX_PATH}/access-management`,
    component: React.lazy(() => import("views/app-views/AccessManagement/")),
  },
  {
    key: "user-management",
    path: `${APP_PREFIX_PATH}/user-management`,
    component: React.lazy(() => import("views/app-views/UserManagement/")),
  },
  {
    key: "security-center",
    path: `${APP_PREFIX_PATH}/security-center`,
    component: React.lazy(() => import("views/app-views/AlertManagement/")),
  },
  {
    key: "account",
    path: `${APP_PREFIX_PATH}/my-account`,
    component: React.lazy(() => import("views/app-views/ProfileManage/")),
  },
];
