import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  loadingModel: true,
  modelData: [],
  modelType: null,
  selectedId: null,
  edit: {},
  creating: false,
  editing: false,
  data: [],
  userByEmail: null,
};

export const companySlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreating: (state, action) => {
      state.creating = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setUserByEmail: (state, action) => {
      state.userByEmail = action.payload;
    },
    setLoadingModel: (state, action) => {
      state.loadingModel = action.payload;
    },
    setModelData: (state, action) => {
      state.modelData = action.payload;
    },
    setModelType: (state, action) => {
      state.modelType = action.payload;
    },
  },
});

export const {
  setData,
  setLoading,
  setSelectedId,
  setEdit,
  setEditing,
  setUserByEmail,
  setLoadingModel,
  setModelData,
  setModelType,
  setCreating,
} = companySlice.actions;

export default companySlice.reducer;
