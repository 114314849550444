import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import alert from "./slices/alertSlice";
import company from "./slices/companySlice";
import user from "./slices/userSlice";
import project from "./slices/projectSlice";
import access from "./slices/accessSlice";
import camera from "./slices/cameraSlice";
import alertManagement from "./slices/alertManagementSlice";
import events from "./slices/eventsSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    alert,
    company,
    user,
    project,
    access,
    camera,
    alertManagement,
    events,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
