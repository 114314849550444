import React, { useEffect, useRef, useState } from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import PropTypes from "prop-types";
import { App, message } from "antd";
import { connect } from "react-redux";
import { removeAlerts } from "store/slices/alertSlice";
import store from "store/";
import { checkToken } from "api/auth/auth";

const Routes = ({ alertQueue, removeAlerts, checkToken, userDetails }) => {
  const check = useRef(null);
  check.current = (alertQueue) => {
    if (alertQueue.length > 0) {
      let alert = alertQueue[0];
      message[alert.isError ? "error" : "success"](alert.message);
      store.dispatch(removeAlerts(alert));
    }
  };

  const initialized = useRef(false);
  const [entry, setentry] = useState(AUTHENTICATED_ENTRY);

  useEffect(() => {
    if (userDetails !== null) {
      if (typeof userDetails.role === "string" && userDetails.role !== null) {
        if (userDetails.role.toLowerCase() === "super-user") {
          setentry("/app/company-management");
        } else if (userDetails.role.toLowerCase() === "company-admin") {
          setentry("/app/project-management");
        } else if (userDetails.role.toLowerCase() === "project-admin") {
          setentry("/app/camera-management");
        } else if (userDetails.role.toLowerCase() === "user") {
          setentry("/app/security-center");
        } else if (userDetails.role.toLowerCase() === "custom-user") {
          setentry("/app/camera-controls");
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      checkToken();
    }
  }, [checkToken]);

  useEffect(() => {
    check.current(alertQueue.filter((ele) => !ele.isComplete));
  }, [alertQueue]);

  return (
    <>
      <App></App>
      <RouterRoutes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Navigate replace to={entry} />} />
          {protectedRoutes.map((route, index) => {
            return (
              <Route
                key={route.key + index}
                path={route.path}
                element={
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                }
              />
            );
          })}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="/" element={<PublicRoute />}>
          {publicRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <AppRoute
                    routeKey={route.key}
                    component={route.component}
                    {...route.meta}
                  />
                }
              />
            );
          })}
        </Route>
      </RouterRoutes>
    </>
  );
};

Routes.propTypes = {
  otherSignIn: PropTypes.bool,
};

Routes.defaultProps = {
  otherSignIn: true,
  showForgetPassword: false,
};

const mapStateToProps = ({ alert, auth }) => {
  const { alertQueue } = alert;
  const { userDetails } = auth;
  return { alertQueue, userDetails };
};
const mapDispatchToProps = { removeAlerts, checkToken };
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
