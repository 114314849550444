import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  selectedId: null,
  selectedTopId: null,
  edit: {},
  creating: null,
  editing: null,
  data: [],
  cameraList: [],
  cameraListLoading: false,
  eventLoading: false,
};

export const eventSlices = createSlice({
  name: "events",
  initialState,
  reducers: {
    reset: () => initialState,
    setData: (state, action) => {
      state.data = action.payload;
    },
    setEventLoading: (state, action) => {
      state.eventLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreating: (state, action) => {
      state.creating = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
      sessionStorage.setItem("selectedId", action.payload);
    },
    setSelectedTopId: (state, action) => {
      state.selectedTopId = action.payload;
      sessionStorage.setItem("selectedTopId", action.payload);
    },
    setEdit: (state, action) => {
      state.edit = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setCameraList: (state, action) => {
      state.cameraList = action.payload;
    },
    setCameraListLoading: (state, action) => {
      state.cameraListLoading = action.payload;
    },
  },
});

export const {
  setData,
  setLoading,
  setSelectedId,
  setEdit,
  setEditing,
  setCreating,
  setSelectedTopId,
  setCameraList,
  setCameraListLoading,
  setEventLoading,
} = eventSlices.actions;

export default eventSlices.reducer;
