import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";
import { initailization } from "api/generic";

const ProtectedRoute = () => {
  const { isAuthenticated, loading, token } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated && !loading && token !== null) {
      initailization();
    }
  }, [isAuthenticated, loading, token]);

  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
